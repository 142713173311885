<ng-container>
  <mat-toolbar>
    <mat-toolbar-row>
      <ng-container *ngFor="let tab of sitesTabs">
        <a *ngIf="tab.hasPermissions" mat-button [routerLink]="tab.routerLink" routerLinkActive="active" [innerHTML]="tab.label"></a>
      </ng-container>
      <span class="spacer"></span>
      <select (change)="onYearChange($event)" [(ngModel)]="selectedYear">
      <option value="2023">2023</option>
      <option value="2024">2024</option>
      <option value="2025">2025</option>
    </select>
      <div *ngIf="isLoggin else logoutTemplate">
        <a mat-button (click)="logout()">Logout</a>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <ng-template #logoutTemplate>
    <a mat-button routerLink="/register">Register</a>
    <a mat-button routerLink="/login">Login</a>
  </ng-template>

</ng-container>
